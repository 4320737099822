import {
    Abstract
} from './Abstract';

import axios from "axios";
export class CustomerPayment extends Abstract {
    constructor(session) {
        super('comercial/Payment', session);
        this.id_customer = null;
        this.amount = null;
        this.status = null;
        this.payment_type = null;
        this.id_bank_account_destination = null;
        this.date = new Date();
        this.currency = null;
        this.exchange_rate = null;
        this.amount_paid = 0.00;
    }

  
    async update() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro para actualizar"
        this.strUsuarioMod = this.usuario;
        let response = await axios.put(this.controller + "/" + this.id, this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
    async pagos(){
        let response = await axios.get(this.controller + "/pagos", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
    // async aplicarpago(Facturas, Pagos) {
        
    // }

    // async aplicarpago() {
    //     let response = await axios.post(this.controller, this, {
    //         headers: {
    //             user: this.user,
    //             company: this.id_company,
    //             branch: this.id_branch
    //         }
    //     });
    //     return response.data;
    // }
}